import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Icon from "../components/Icon";
import ImageCarousel from "../components/ImageCarousel";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import bubbles from "../images/bubbles.svg";
import quoteLeft from "../images/quote_left.svg";
import quoteRight from "../images/quote_right.svg";
import jobs from "../images/jobs.svg";
import teacher from "../images/teacher.svg";
import handshake from "../images/handshake.svg";
import YouTubeVideo from "../components/YouTubeVideo";
import PopUpDialog from "../components/PopUpDialog";

const colors = ["#0086B3", "#5AA79D", "#EDCE52"];

const blurbImages = {
  briefcase: {
    src: jobs,
    altText: "Briefcase and magnifying glass",
  },
  teacher: {
    src: teacher,
    altText: "Teacher with chalkboard",
  },
  handshake: {
    src: handshake,
    altText: "Handshake",
  },
};

export const IndexPageTemplate = (props) => {
  const {
    image,
    heading,
    subheading,
    welcome,
    overview,
    values,
    students,
    testimonials,
    popupImage,
  } = props;
  return (
    <>
      <PopUpDialog fluid={popupImage.childImageSharp.fluid} />
      <div className="landing-banner-container">
        <div className="overlay">
          <div
            className="bubbles"
            style={{ backgroundImage: `url(${bubbles})` }}
          ></div>
          <div className="layout-container">
            <div className="content">
              <h1>{heading}</h1>
              <p>{subheading}</p>
              <a href="#welcome" className="btn">
                LEARN MORE
              </a>
            </div>
          </div>
        </div>
        <div className="landing-banner">
          <PreviewCompatibleImage
            imageData={image}
            alt="Philips Academy home page"
            gatsbyImageProps={{
              className: "landing-gatsby-image",
              loading: "eager",
            }}
          />
        </div>
      </div>
      <div className="landing-rounded-section" id="welcome">
        <div className="layout-container">
          <div className="width-container text-center">
            <div className="landing-header-container">
              <h3>{welcome.heading}</h3>
            </div>
            <p>{welcome.subheading}</p>
          </div>
        </div>
        <div className="svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="none"
            viewBox="0 0 100 5"
            width="100%"
            height="100%"
          >
            <path
              fill="currentColor"
              d="M107.5 -2.5C107.5 0.26 81.74 2.5 50 2.5C18.26 2.5 -7.5 0.26 -7.5 -2.5C-7.5 -5.26 18.26 -7.5 50 -7.5C81.74 -7.5 107.5 -5.26 107.5 -2.5Z"
            />
          </svg>
        </div>
      </div>
      <div>
        <div className="main-landing-container">
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <div key={`ball-${i}`} className="landing-ball">
              <div className="ball-img" />
            </div>
          ))}
          <section className="landing-keys-section">
            <div className="layout-container">
              <div className="landing-header-container text-center">
                <h3>{overview.heading}</h3>
              </div>
              <div className="grid-container add-width">
                {overview.blurbs.map(
                  ({ link, heading, subheading, icon }, index) => {
                    let to = link.indexOf("/") === -1 ? `/${link}/` : link;
                    return (
                      <div
                        className="grid-item grid-item-3"
                        key={`paper-item-${index}`}
                      >
                        <Link className="landing-paper-container" to={to}>
                          <div className="paper-body landing">
                            <img
                              alt={blurbImages[icon].altText}
                              src={blurbImages[icon].src}
                              className="icon-img"
                            />
                            <h4>{heading}</h4>
                            <p>{subheading}</p>
                            <div className="btn-container">
                              <button className="btn white-btn">
                                LEARN MORE
                              </button>
                            </div>
                          </div>
                          <div className="paper-background" />
                        </Link>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </section>
          <section className="grid-container row-reverse-section values-section">
            <div className="grid-item grid-item-2">
              <div className="landing-values-content">
                <div className="landing-header-container alt-header values-header">
                  <h3>{values.heading}</h3>
                </div>
                <table className="landing-table">
                  <tbody>
                    {values.items.map((value, index) => {
                      return (
                        <tr key={`row-${index}`}>
                          <td className="check-cell">
                            <div className="check-container">
                              <Icon iconName="check" width={20} />
                            </div>
                          </td>
                          <td>{value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="btn-container">
                  <Link to="/about/" className="btn primary-btn full-width-sm">
                    DISCOVER MORE ABOUT US
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid-item grid-item-2">
              <div className="layout-container position-relative">
                <PreviewCompatibleImage
                  imageData={values.bigImage}
                  className="landing-grid-img"
                  alt="Students playing music in class"
                />
                <div className="landing-grid-img-small">
                  <PreviewCompatibleImage
                    imageData={values.smallImage}
                    alt="Students learning about artwork in class"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="layout-container landing-vid-section">
            <div className="width-container text-center">
              <div className="landing-header-container">
                <h3>{students.heading}</h3>
              </div>
            </div>
            <div className="vid-container">
              <YouTubeVideo src={students.video} className="landing-video" />
            </div>
            <div className="width-container text-center">
              <p>{students.subheading}</p>
              <div className="grid-container btn-group add-width">
                <div className="grid-item grid-item-2">
                  <Link to="/admissions/" className="btn primary-btn btn-1">
                    VIEW ADMISSIONS
                  </Link>
                </div>
                <div className="grid-item grid-item-2">
                  <Link to="/support/" className="btn primary-btn btn-2">
                    SUPPORT US
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {students.images && <ImageCarousel images={students.images} />}
        </div>
        <section className="testimonial-section">
          <div
            className="quote-img top-quote"
            style={{ backgroundImage: `url(${quoteLeft})` }}
          ></div>
          <div
            className="quote-img bottom-quote"
            style={{ backgroundImage: `url(${quoteRight})` }}
          ></div>
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              preserveAspectRatio="none"
              viewBox="0 0 100 5"
              width="100%"
              height="100%"
            >
              <path
                fill="currentColor"
                d="M107.5 7.5C107.5 10.26 81.74 12.5 50 12.5C18.26 12.5 -7.5 10.26 -7.5 7.5C-7.5 4.74 18.26 2.5 50 2.5C81.74 2.5 107.5 4.74 107.5 7.5Z"
              />
            </svg>
          </div>
          <div className="layout-container position-relative">
            <h3 className="text-center">{testimonials.heading}</h3>
            <p className="text-center width-container">
              {testimonials.subheading}
            </p>
            <span className="divider" />
            <div className="grid-container">
              {testimonials.items.map(({ testimonial, author }, index) => {
                return (
                  <div
                    key={`testimonial-${index}`}
                    className="grid-item-3 grid-item"
                  >
                    <div className="text-center">
                      <div
                        className="testimonial-user-icon"
                        style={{ backgroundColor: colors[index] }}
                      >
                        <Icon iconName="user" width={60} />
                      </div>
                    </div>
                    <p className="quote text-left">"{testimonial}"</p>
                    <p className="text-left">- {author}</p>
                  </div>
                );
              })}
            </div>
            <div className="text-center">
              <Link
                to="/contact/"
                className="btn primary-btn white-btn full-width-sm"
              >
                CONTACT US TODAY
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  welcome: PropTypes.object,
  overview: PropTypes.object,
  values: PropTypes.object,
  students: PropTypes.object,
  testimonials: PropTypes.object,
  popupImage: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const {
    frontmatter: {
      image,
      heading,
      subheading,
      welcome,
      overview,
      values,
      students,
      testimonials,
    },
  } = data.markdownRemark;
  return (
    <Layout pageTitle={`Life-Centered, Hands-On Special Education`} ogUrl="/">
      <IndexPageTemplate
        image={image}
        heading={heading}
        subheading={subheading}
        welcome={welcome}
        overview={overview}
        values={values}
        students={students}
        testimonials={testimonials}
        popupImage={data.popupImage}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    popupImage: file(relativePath: { eq: "kitchen.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        welcome {
          heading
          subheading
        }
        overview {
          heading
          blurbs {
            icon
            heading
            link
            subheading
          }
        }
        values {
          heading
          items
          bigImage {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          smallImage {
            childImageSharp {
              fluid(maxWidth: 360, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        students {
          heading
          subheading
          video
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          heading
          subheading
          items {
            author
            testimonial
          }
        }
      }
    }
  }
`;
