import React from 'react'
import Icon from './Icon'

const YouTubeVideo = ({ src, width, height, className }) => {
    const [active, setActive] = React.useState(false);
    const videoSegments = src.split('?')[0].split('/');
    const videoID = videoSegments[videoSegments.length - 1];
    const imgSrc = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
    const props = className ? {className: `youtube-container ${className}`} : {className: 'youtube-container', style: { height, width } };
    return (
        <div {...props}>
            {active && <div className='loading-msg'>Loading video...</div>}
            {active ? 
            <iframe 
                title="YouTube video" 
                width="100%"
                height="100%"
                src={`${src}?autoplay=1`} 
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen 
            />
            :
            <img 
                src={imgSrc} 
                alt="YouTube video thumbnail" 
                loading="lazy" 
                className='youtube-img'
                width="100%"
                height="100%"
            />
            }
            {!active && <button className='youtube-btn' onClick={() => setActive(true)}>
                <span />
                <Icon iconName='youtube' width={80} />
            </button>}
        </div>
    )
}

export default YouTubeVideo