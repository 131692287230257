import React from "react";
import GatsbyImage from "gatsby-image";
import Dialog from "@material-ui/core/Dialog";
import Icon from "./Icon";

const POP_UP_LAST_VIEWED = "POP_UP_LAST_VIEWED";
const POP_UP_ENABLED = true;

export default ({ fluid }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const popUpLastViewed = window.localStorage.getItem(POP_UP_LAST_VIEWED);
    const now = Date.now();

    const startTime = new Date("2024-07-14T00:00:00Z").getTime();
    const endTime = new Date("2024-07-20T00:00:00Z").getTime();

    if (
      POP_UP_ENABLED &&
      now > startTime &&
      now < endTime &&
      (!popUpLastViewed ||
        parseInt(popUpLastViewed) + 60 * 60 * 24 * 1000 < now)
    ) {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
  }, []);

  function handleClick() {
    window.localStorage.setItem(POP_UP_LAST_VIEWED, `${Date.now()}`);
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick
      fullWidth
      PaperProps={{ style: { maxWidth: 600 } }}
      scroll="body"
    >
      <div className="position-relative">
        <button
          title="Close"
          onClick={handleClick}
          className="btn"
          style={{
            background: "none",
            color: "#555555",
            position: "absolute",
            top: 4,
            right: 4,
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 20,
          }}
        >
          <Icon iconName="times" width={16} />
        </button>
        <div>
          <>
            <div style={{ background: "white", padding: 16 }}>
              <div style={{ maxWidth: 450, margin: "auto" }}>
                <GatsbyImage fluid={fluid} />
              </div>
            </div>
            <div
              className="text-center"
              style={{
                paddingBottom: 24,
                position: "relative",
                zIndex: 10,
                marginTop: 12,
              }}
            >
              <a
                className="btn primary-btn"
                href="https://a.co/dZmXj8D"
                target="_blank"
                rel="noopener noreferrer"
              >
                LEARN MORE
              </a>
            </div>
          </>
        </div>
      </div>
    </Dialog>
  );
};
