import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Icon from './Icon'

const ICON_DIMS = 20;

const ImageCarousel = ({ images }) => {
    const ref = React.useRef(null);
    const items = images.map( ({image}, index) => {
        return (
            <div className='carousel-img-container' key={`img-${index}`}>
                <PreviewCompatibleImage alt="Student life" imageData={image}  gatsbyImageProps={{ draggable: false, fadeIn: false }} />
            </div>
        )
    });
    return (
        <div className='alice-carousel-container'>
            <div className='alice-carousel'>
                <AliceCarousel
                    mouseTrackingEnabled
                    dotsDisabled={true}
                    buttonsDisabled={true}
                    items={items}
                    ref={ref}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2 },
                        900: { items: 3 }
                    }}
                />
                <button className='btn carousel-btn prev' onClick={() => ref.current.slidePrev()}>
                    <Icon width={ICON_DIMS} iconName="chevronLeft" />
                </button>
                <button className='btn carousel-btn next' onClick={() => ref.current.slideNext()}>
                    <Icon width={ICON_DIMS} iconName="chevronRight" />
                </button>
            </div>
        </div>
    )
}

export default ImageCarousel